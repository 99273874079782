import styled from "styled-components";

import Arrow from "../../../assets/svg/icons/ArrowNE.svg";

import {
  COLORS
} from "../../../theme/style-constants";


export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  color: ${ COLORS.text.black };
  transition: all 0.3s ease-in-out;

  h3 {
    margin: 0;
  }
`;

export const ButtonArrow = styled(Arrow)`
  height: 0.8rem;
  width: 0.8rem;
  transition: all 0.3s ease-in-out;
`;

export const StyledButton = styled.button`
  display: inline-block;
  width: 100%;
  padding: 0;
  background: transparent;
  border: 0;
  color: ${ props => ( props.darkBackground === true ? COLORS.text.light : COLORS.text.dark ) };
  text-decoration: none;
  -webkit-appearance: none;

  &:hover {
    cursor: pointer;

    ${ ButtonContainer } {
      color: ${ COLORS.text.orange };
    }

    ${ ButtonArrow } {
      stroke: ${ COLORS.text.orange };
    }
  }

  &:visited {
    color: ${ props => ( props.darkBackground === true ? COLORS.text.light : COLORS.text.dark ) };
  }
`;
