import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

import Arrow from "../../../assets/svg/icons/ArrowNE.svg";

import {
  COLORS,
  SCREENS
} from "../../../theme/style-constants";


export const LinkArrow = styled(Arrow)`
  height: 0.7rem;
  width: 0.7rem;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem 2rem;

  @media (min-width: ${ SCREENS.tablet }) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledColumn = styled.div`
  border-bottom: 1px solid ${ COLORS.border.dark };
`;

export const StyledColumnImage = styled(GatsbyImage)`

`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledHeading = styled.h3``;
