import * as React from "react";
import { useState } from "react";

import {
  ButtonArrow,
  ButtonContainer,
  StyledButton
} from "./style";

import Modal from "../../Modal";


const ModalLinkButton = ({
  buttonText,
  control
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <StyledButton
        onClick={ () => setModalOpen(true) }
      >
        <ButtonContainer>
          <h3>{ buttonText }</h3>
          <ButtonArrow />
        </ButtonContainer>
      </StyledButton>
      <Modal
        modalOpen={ modalOpen }
        close={ () => setModalOpen(false) }
        control={ control }
      />
    </>
  )
}


export default ModalLinkButton;
