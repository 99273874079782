import styled from "styled-components";

import Close from "../../assets/svg/icons/Close.svg";

import {
  COLORS
} from "../../theme/style-constants";


export const StyledModal = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${ props => props.modalOpen ? `${ COLORS.background.dark }99` : `transparent` };
  opacity: ${ props => props.modalOpen ? `1` : `0` };
  transition: all 0.3s ease-in-out;
  z-index: ${ props => props.modalOpen ? `1000` : `-10` };
`;

export const ModalContainer = styled.div`
  position: relative;
  padding: 2rem;
  background: ${ COLORS.background.light };
  border-radius: 1rem;
`;

export const CloseButton = styled(Close)`
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all 0.2s ease-in-out;
`;

export const ButtonContainer = styled.span`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 28px;
  height: 28px;
  color: ${ COLORS.text.dark };
  cursor: pointer;

  &:hover {
    ${ CloseButton } {
      opacity: 0.7;
      transition: all 0.2s ease-in-out;
    }
  }
`;
