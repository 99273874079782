import styled from "styled-components";

import Explore from "../../../../assets/svg/Explore.svg";

import {
  SCREENS
} from "../../../../theme/style-constants";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 14rem;
  height: 20rem;
  margin: 1rem;

  @media (min-width: ${ SCREENS.tablet }) {
    width: 20rem;
    height: 20rem;
  }
`;

export const SiteLogo = styled(Explore)`
  width: 12rem;
`;

export const StyledTextArea = styled.p`
  text-align: center;
  font-size: 1.4rem;
`;
