import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import {
  StyledColumn,
  StyledColumnImage,
  StyledGrid
} from "./style";

import ModalLinkButton from "../../../components/Button/ModalLinkButton";


const PortfolioGrid = () => {
  const { portfolio } = useStaticQuery(graphql`
    query {
      portfolio: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "portfolio"
            }
            isPortfolioFeatured: {
              eq: true
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              title
              portfolioImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    quality: 80
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);
  
  return (
    <StyledGrid>
      { portfolio.edges.map((node) => {
        return (
          <StyledColumn>
            <StyledColumnImage
              image={ getImage(node.node.frontmatter.portfolioImage) }
            />
            <ModalLinkButton
              buttonText={ node.node.frontmatter.title }
              control="instagram"
            />
          </StyledColumn>
        )
      })}
    </StyledGrid>
  )
}


export default PortfolioGrid;
