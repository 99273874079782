import * as React from "react";

import Section from "../components/Section";

import CallToAction from "../components/CallToAction";
import Grid from "../components/Grid";
import Layout from "../components/Layout";
import PageHero from "../components/Hero/PageHero";
import SEO from "../components/SEO";
import Ticker from "../components/Ticker";

import PortfolioGrid from "../domain/Portfolio/PortfolioGrid";
import SecondaryButton from "../components/Button/SecondaryButton";


const PortfolioPage = () => {
  return (
    <Layout>
      <SEO title="Our Portfolio" />
      
      <PageHero
        pageTitle="Our Portfolio"
        pageDescription="We have had the pleasure of working with small and large organizations in all industries to help redefine their digital marketing efforts."
        Component={
          <Grid
            Component={
              <PortfolioGrid />
            }
          />
        }
      />

      <Section
        sectionTitle="Award-Winning Digital Marketing Agency"
        darkBackground
        textAlignment="center"
        Component={
          <CallToAction
            darkBackground
            ButtonComponent={
              <SecondaryButton
                linkLocation="/contact"
                linkText="Start A Project"
                darkBackground
              />
            }
          />
        }
      />

      <Section
        Component={
          <Ticker
            template="marketing-partner"
            itemWidth={ 16 }
          />
        }
      />
    </Layout>
  )
}


export default PortfolioPage;
