import * as React from "react";

import {
  Container,
  SiteLogo,
  StyledTextArea
} from "./style";

import SecondaryButton from "../../../Button/SecondaryButton";


const InstagramCTA = () => {
  return (
    <Container>
      <SiteLogo />
      <StyledTextArea>
        Take a look at our latest work, fun facts, and design inspiration on our Instagram!
      </StyledTextArea>
      <SecondaryButton
        isExternal
        linkLocation="https://www.instagram.com/explore.agency/"
        linkText="View Here"
      />
    </Container>
  )
}


export default InstagramCTA;
