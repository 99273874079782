import * as React from "react";
import ModalControl from "./ModalControl";

import {
  ButtonContainer,
  CloseButton,
  ModalContainer,
  StyledModal
} from "./style";


const Modal = ({
  modalOpen,
  close,
  control
}) => {
  return (
    <StyledModal
      modalOpen={ modalOpen }
    >
      <ModalContainer>
        <ButtonContainer
          onClick={ close }
        >
          <CloseButton />
        </ButtonContainer>
        <ModalControl
          control={ control }
        />
      </ModalContainer>
    </StyledModal>
  )
}


export default Modal;
