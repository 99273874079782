import * as React from "react";

import InstagramCTA from "../Layouts/InstagramCTA";


const ModalControl = ({
  control,
  ...props
}) => {
  switch (control) {
    case 'instagram':
      return <InstagramCTA />;
    default:
      return null;
  }
}


export default ModalControl;
